import { CButton, CFormInput, CMultiSelect, CTableDataCell, CTableRow } from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilImage } from '@coreui/icons'
import { useState } from 'react'
import { BundleItem, RegisteredProduct } from '../../types'

interface NewBundleItemRowProps {
    selectableProducts: RegisteredProduct[]
    onAddClick: (bundleItem: BundleItem) => void
}

interface NullableBundleItem {
    product: RegisteredProduct | null
    quantity: number
}

export const NewBundleItemRow = ({ onAddClick, selectableProducts }: NewBundleItemRowProps) => {
    const [newBundleItem, setNewBundleItem] = useState<NullableBundleItem>({
        product: null,
        quantity: 1,
    })

    const handleNewProductSelected = (productId: string) => {
        const product = selectableProducts.find((p) => p.id === productId)

        if (!product) {
            return
        }

        setNewBundleItem((oldValue) => ({
            product,
            quantity: oldValue.quantity,
        }))
    }

    const handleNewProductQuantityChange = (quantity: number) => {
        setNewBundleItem((oldValue) => ({
            product: oldValue.product,
            quantity,
        }))
    }

    const handleAddClick = () => {
        if (!newBundleItem.product) {
            return
        }

        onAddClick({
            product: newBundleItem.product,
            quantity: newBundleItem.quantity,
        })

        setNewBundleItem({
            product: null,
            quantity: 1,
        })
    }

    return (
        <CTableRow>
            <CTableDataCell>
                {!newBundleItem?.product?.listImageUrl && (
                    <CIcon size={'xxl'} icon={cilImage} className="text-danger m-2" />
                )}
                {newBundleItem?.product?.listImageUrl && (
                    <img
                        src={newBundleItem.product.listImageUrl}
                        width={56}
                        height={56}
                        style={{ borderRadius: '50%' }}
                        alt="Et bilde av produktet"
                    />
                )}
            </CTableDataCell>
            <CTableDataCell>
                <CFormInput
                    type="number"
                    value={newBundleItem.quantity}
                    onChange={(e) => handleNewProductQuantityChange(Number(e.target.value))}
                    placeholder="Enter amount"
                    min={1}
                />
            </CTableDataCell>
            <CTableDataCell colSpan={3}>
                <CMultiSelect
                    options={selectableProducts.map((product) => {
                        return { value: product.id, text: product.title, product }
                    })}
                    optionsTemplate={(option) => (
                        <div>
                            <img
                                src={option.product.listImageUrl}
                                width={20}
                                height={20}
                                style={{ borderRadius: '50%', marginRight: 10 }}
                                alt="Et bilde av produktet"
                            />
                            {option.text}
                        </div>
                    )}
                    multiple={false}
                    onChange={(e) => handleNewProductSelected(`${e[0].value}`)}
                ></CMultiSelect>
            </CTableDataCell>
            <CTableDataCell>
                <CButton type="button" color="primary" disabled={!newBundleItem.product} onClick={handleAddClick}>
                    {'Legg til'}
                </CButton>
            </CTableDataCell>
        </CTableRow>
    )
}
