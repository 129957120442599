import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { getAuthorizedBaseQuery } from '../../common/utils/apiUtils'
import { Bundle } from '../types'

export const bundleApi = createApi({
    reducerPath: 'bundle',
    baseQuery: getAuthorizedBaseQuery(),
    tagTypes: ['Bundle', 'ProductRegistryListPage'],
    endpoints: (builder) => ({
        getBundles: builder.query<Bundle[], void>({
            query: () => ({
                url: '/admin/bundle',
                method: 'GET',
            }),
            providesTags: ['Bundle'],
        }),
        getBundle: builder.query<Bundle, { bundleId: string }>({
            query: ({ bundleId }) => ({
                url: `/admin/bundle/${bundleId}`,
                method: 'GET',
            }),
        }),
        updateProductBundle: builder.mutation<
            void,
            {
                bundleId: string
                contains: {
                    productId: string
                    quantity: number
                }[]
            }
        >({
            query: ({ bundleId, contains }) => ({
                url: `/admin/bundle/${bundleId}`,
                method: 'PUT',
                body: { contains },
            }),
            invalidatesTags: ['Bundle', 'ProductRegistryListPage'],
        }),
    }),
})

export const { useGetBundlesQuery, useGetBundleQuery, useUpdateProductBundleMutation } = bundleApi
