import { Bundle, BundleItem, RegisteredProduct } from '../../types'
import Card from '../../../common/components/Card'
import CardHeader from '../../../common/components/CardHeader'
import { CCardBody } from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilBasket, cilPencil, cilX } from '@coreui/icons'
import React, { useEffect, useState } from 'react'
import { BundleProductItemsTable } from './BundleProductItemsTable'

interface BundleProductCardProps {
    product: RegisteredProduct
    products: RegisteredProduct[]
    bundle?: Bundle
    bundles: Bundle[]
}

export default function ProductBundleCard({ product, products, bundle, bundles }: BundleProductCardProps) {
    const [isEditing, setIsEditing] = useState<boolean>(false)

    useEffect(() => {
        setIsEditing(false)
    }, [product, products, bundle])

    const isProductABundle = (productId: string) => {
        return bundles.some((bundle) => bundle.id === productId)
    }

    const productsThatAreNotBundles = products.filter((product) => !isProductABundle(product.id))

    const handleCardActionClick = () => {
        if (isEditing) {
            if (!window.confirm('Du kan ha ulagrede endringer. Er du sikker på at du vil avbryte?')) {
                return
            }
        }
        setIsEditing(!isEditing)
    }

    return (
        <Card
            onAction={product.status === 'ACTIVE' ? undefined : handleCardActionClick}
            actionIcon={isEditing ? cilX : cilPencil}
        >
            <CardHeader>
                <CIcon icon={cilBasket} size={'xl'} className={'mr-1'} />
                Produkt bundle
            </CardHeader>
            <CCardBody>
                {!isEditing && bundle?.contains?.length === 0 && (
                    <p style={{ color: 'var(--cui-red)' }}>Denne bundlen mangler produkter</p>
                )}
                <p style={{ color: 'var(--cui-gray)' }}>
                    Dette produktet er en bundle av andre produkter. Produktene kan ikke være bundles.
                </p>
                <BundleProductItemsTable
                    setIsEditing={setIsEditing}
                    bundleId={product.id}
                    bundleItems={bundle?.contains || []}
                    products={productsThatAreNotBundles}
                    isEditing={isEditing}
                />
            </CCardBody>
        </Card>
    )
}
