import { CButton, CTableDataCell, CTableRow } from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilImage } from '@coreui/icons'
import { BundleItem, RegisteredProduct } from '../../types'

type Status = 'DELETED' | 'UPDATED' | 'NEW' | 'UNCHANGED'

interface BundleProductItemsTableRowProps {
    bundleItem: BundleItem
    products: RegisteredProduct[]
    onDelete: (productId: string) => void
    status: Status
    isEditing: boolean
}

export const BundleProductItemsTableRow = ({
    bundleItem,
    products,
    status,
    isEditing,
    onDelete,
}: BundleProductItemsTableRowProps) => {
    const product = products.find((p) => p.id === bundleItem.product.id)
    if (!product) {
        return null
    }

    const handleRowClick = (product: RegisteredProduct) => {
        if (isEditing) {
            return
        }

        window.location.href = `/produkter/${product.id}`
    }

    const handleDeleteClick = () => {
        onDelete(product.id)
    }

    const color = () => {
        switch (status) {
            case 'NEW':
                return 'success'
            case 'DELETED':
                return 'danger'
            case 'UPDATED':
                return 'warning'
            default:
                return undefined
        }
    }

    return (
        <CTableRow color={color()} key={bundleItem.product.id} onClick={() => handleRowClick(product)}>
            <CTableDataCell>
                {!product.listImageUrl && <CIcon size={'xxl'} icon={cilImage} className="text-danger m-2" />}
                {product.listImageUrl && (
                    <img
                        src={product.listImageUrl}
                        width={56}
                        height={56}
                        style={{ borderRadius: '50%' }}
                        alt="Et bilde av produktet"
                    />
                )}
            </CTableDataCell>
            <CTableDataCell style={{ paddingLeft: '1rem' }}>{bundleItem.quantity}</CTableDataCell>
            <CTableDataCell>{product?.externalProductId}</CTableDataCell>
            <CTableDataCell>{product?.title}</CTableDataCell>
            <CTableDataCell>{product?.supplierName}</CTableDataCell>
            <CTableDataCell>
                {isEditing && status !== 'DELETED' && (
                    <CButton type={'button'} color={'danger'} onClick={handleDeleteClick}>
                        Slett
                    </CButton>
                )}
            </CTableDataCell>
        </CTableRow>
    )
}
