import { CButton, CTable, CTableBody, CTableDataCell, CTableRow } from '@coreui/react-pro'
import { BundleItem, RegisteredProduct } from '../../types'
import { useEffect, useState } from 'react'
import { BundleProductItemsTableRow } from './BundleProductItemsTableRow'
import { useUpdateProductBundleMutation } from '../../store/bundleApi'
import { addToast } from '../../../common/store/commonSlice'
import { useDispatch } from 'react-redux'
import { NewBundleItemRow } from './NewBundleItemRow'

interface BundleProductItemsTableProps {
    bundleId: string
    bundleItems: BundleItem[]
    products: RegisteredProduct[]
    isEditing: boolean
    setIsEditing: (isEditing: boolean) => void
}

export const BundleProductItemsTable = ({
    bundleId,
    bundleItems,
    products,
    isEditing,
    setIsEditing,
}: BundleProductItemsTableProps) => {
    const [updatedBundleItems, setUpdatedBundleItems] = useState(bundleItems)

    const deletedBundleItems = bundleItems.filter(
        (bundleItem) => !updatedBundleItems.some((b) => b.product.id === bundleItem.product.id)
    )
    const [updateBundle, updateBundleResult] = useUpdateProductBundleMutation()
    const dispatch = useDispatch()

    useEffect(() => {
        setUpdatedBundleItems(bundleItems)
    }, [bundleItems, isEditing])

    const columns = [
        { key: 'listImageUrl', label: '' },
        { key: 'amount', label: 'Antall', _style: { width: '60px' } },
        { key: 'externalProductId', label: 'Ekstern produktid' },
        { key: 'title', label: 'Navn' },
        { key: 'supplierName', label: 'Leverandør' },
        { key: 'delete', label: '', _style: { width: '100px' } },
    ]

    const handleAddProductClick = (bundleItem: BundleItem) => {
        setUpdatedBundleItems((prev) => [...prev, bundleItem])
    }

    const handleDeleteClick = (productId: string) => {
        setUpdatedBundleItems((prev) => prev.filter((b) => b.product.id !== productId))
    }

    const handleSave = () => {
        updateBundle({
            bundleId: bundleId,
            contains: updatedBundleItems.map((bundleItem) => ({
                productId: bundleItem.product.id,
                quantity: bundleItem.quantity,
            })),
        })
    }

    useEffect(() => {
        if (updateBundleResult.isSuccess) {
            dispatch(
                addToast({
                    message: 'Bundle ble oppdatert',
                    color: 'success',
                })
            )
            setIsEditing(false)
        }

        if (updateBundleResult.isError) {
            if ('data' in updateBundleResult.error) {
                dispatch(
                    addToast({
                        message: 'Kunne ikke oppdatere bundle: ' + updateBundleResult.error.data.message,
                        color: 'danger',
                    })
                )
            }
        }
    }, [updateBundleResult])

    const isProductActive = (product: RegisteredProduct) => product.status === 'ACTIVE'
    const isProductInUpdatedBundle = (product: RegisteredProduct) =>
        updatedBundleItems.some((b) => b.product.id === product.id)
    const isProductInExistingBundle = (product: RegisteredProduct) =>
        bundleItems.find((b) => b.product.id === product.id)

    return (
        <CTable columns={columns} borderless small hover caption="top" align={'middle'}>
            <CTableBody>
                {deletedBundleItems.map((bundleItem) => (
                    <BundleProductItemsTableRow
                        key={bundleItem.product.id}
                        bundleItem={bundleItem}
                        products={products}
                        onDelete={handleDeleteClick}
                        status={'DELETED'}
                        isEditing={isEditing}
                    />
                ))}
                {updatedBundleItems.map((bundleItem) => (
                    <BundleProductItemsTableRow
                        key={bundleItem.product.id}
                        bundleItem={bundleItem}
                        products={products}
                        onDelete={handleDeleteClick}
                        status={isProductInExistingBundle(bundleItem.product) ? 'UNCHANGED' : 'NEW'}
                        isEditing={isEditing}
                    />
                ))}
                {isEditing && (
                    <>
                        <NewBundleItemRow
                            selectableProducts={products
                                .filter(isProductActive)
                                .filter((product) => !isProductInUpdatedBundle(product))}
                            onAddClick={handleAddProductClick}
                        />
                        <CTableRow>
                            <CTableDataCell colSpan={5} />
                            <CTableDataCell>
                                <CButton type={'button'} color="primary" onClick={handleSave} style={{ marginTop: 10 }}>
                                    Lagre
                                </CButton>
                            </CTableDataCell>
                        </CTableRow>
                    </>
                )}
            </CTableBody>
        </CTable>
    )
}
